<!--
<template>
  <div class="taps" ref="taps">
    <div class="slider" v-if="isSliderVisible" ref="slider"></div>
    <slot></slot>
  </div>
</template>
-->

<script>
import Tab from './Tab'
export default {
  name: 'Tabs',
  props: {
    current: [String, Number]
  },
  data () {
    return {
      currentIndex: null,
      sliderAnimate: false
    }
  },
  computed: {
    isSliderVisible () {
      return this.currentIndex !== null
    }
  },
  mounted () {
    this.refresh()
  },
  updated () {
    this.refresh()
  },
  methods: {
    refresh () {
      this.setCurrentIndex()
      this.setSlider()
    },
    setCurrentIndex () {
      const type = typeof this.current
      if (type === 'number') {
        this.currentIndex = this.current
      } else if (type === 'string') {
        const currentIndex = this.$refs.tabs.findIndex(e => {
          return e.name === this.current
        })
        this.currentIndex = currentIndex === -1 ? null : currentIndex
      } else {
        this.currentIndex = null
      }
    },
    setSlider () {
      this.$nextTick(_ => {
        const items = this.$refs.tabs
        if (!items) return
        const tab = this.$refs.tab
        const slider = this.$refs.slider
        const boxWidth = tab.offsetWidth
        const itemWidth = boxWidth / items.length
        const sliderWidth = slider.offsetWidth
        const sliderOffsetx = (itemWidth - sliderWidth) / 2
        const left = (itemWidth * this.currentIndex) + sliderOffsetx
        slider.style.transform = 'translateX(' + left + 'px)'
        slider.style.transitionDuration = this.sliderAnimate ? '0.25s' : '0s'
      })
    },
    actionItemDidSelected (index) {
      this.sliderAnimate = true
      const tab = this.$refs.tabs[index]
      const val = tab.name ? tab.name : index
      this.$emit('currentChanged', val)
    }
  },
  render (h) {
    var Tabs = this.$scopedSlots.default().filter(item => {
      return item.componentOptions && item.componentOptions.tag === 'tab'
    })
    Tabs = Tabs.map((item, index) => {
      return h(Tab, {
        ref: 'tabs',
        refInFor: true,
        style: {
          flex: 1
        },
        attrs: {
          isActive: index === this.currentIndex,
          name: item.componentOptions.propsData.name
        },
        scopedSlots: {
          default () {
            return item.componentOptions.children
          }
        },
        on: {
          click: _ => {
            this.actionItemDidSelected(index)
          }
        }
      })
    })
    const Slider = h('div', {
      class: 'slider',
      ref: 'slider',
      style: {
        display: this.isSliderVisible ? 'block' : 'none'
      }
    })
    return h('div', {
      class: 'tabs',
      ref: 'tab'
    }, Tabs.concat(Slider))
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/var';
.tabs {
  background-color: white;
  height: 50px;
  position: relative;
  display: flex;

  .slider {
    width: 26px;
    height: 4px;
    border-radius: 2px;
    background-color: $-color-primary;
    position: absolute;
    bottom: 4px;
    transition: all .25s ease;
  }
}
</style>
