<template>
  <div class="orders">
    <header v-if="hideHeader">
      <van-nav-bar
        :left-arrow="true"
        :title="$t.meta.order"
        @click-left="onClickLeft"
      ></van-nav-bar>
    </header>
    <van-sticky>
      <tabs
        :current="state"
        class="top-bar orders-top-row"
        @currentChanged="stateChanged"
      >
        <tab name="0">{{ $t.order.stateAll }}</tab>
        <tab name="10">{{ $t.order.stateUnpay }}</tab>
        <tab name="20">{{ $t.order.stateWaitSending }}</tab>
        <tab name="30">{{ $t.order.stateReceiving }}</tab>
        <tab name="1">{{ $t.order.stateFinish }}</tab>
      </tabs>
    </van-sticky>
    <van-pull-refresh v-model="refreshing" @refresh="request(1)">
      <van-list
        class="list"
        v-model="loading"
        :finished="finished"
        :finished-text="$t.order.noMore"
        :error.sync="error"
        :error-text="errorText"
        @load="request(page + 1)"
      >
        <div
          class="order"
          v-for="order in content"
          :key="order.AllOrderNumber"
          @click="actionDetail(order.AllOrderNumber)"
        >
          <div class="product">
            <div class="header">
              <h2 class="orderId">
                {{ $t.orderDetails.orderNumber }} : {{ order.AllOrderNumber }}
              </h2>
            </div>
            <div class="sub-header">
              <div
                class="state"
                :class="[
                  order.State === 30 ? 'blue' : '',
                  order.State === 10 ? 'red' : '',
                ]"
              >
                <div v-if="order.PaymentFailurePrompt">
                  {{ order.PaymentFailurePrompt }}
                </div>
                <div v-else>{{ order.StateStr }}</div>
              </div>
            </div>

            <div
              class="item"
              v-for="(product, index) in order.OrderList"
              :key="index"
            >
              <van-image
                class="shopImg"
                :src="
                  product.OrderProducts.ProductPhoto | splitFirstItem | imageSrc
                "
              >
                <template v-slot:error>
                  <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                </template>
              </van-image>

              <div class="content">
                <p class="name">{{ product.OrderProducts.ProductName }}</p>
                <div class="detail">
                  <p class="size">
                    {{ product.OrderProducts.ProductModelStr }}
                  </p>
                  <p class="count">x {{ product.OrderProducts.Number }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="dateTime">{{ order.CreateTime }}</div>

          <p class="info">
            {{ order.OrderList ? order.OrderList.length : 0
            }}{{ $t.order.ordersTtitleInfo }}{{ order.AllFreight }})
            <span class="m">$</span>
            <span class="money">{{ order.PayAllPrice }}</span>
          </p>
          <div class="actions" v-if="order.State === 10 || order.State === 30">
            <div
              class="btn red"
              v-if="order.State === 10"
              @click.stop="actionDelete(order.AllOrderNumber)"
            >
              <div>{{ $t.pay.cancelOrder }}</div>
            </div>
            <div
              class="btn red"
              v-if="order.State === 10"
              @click.stop="actionDetail(order.AllOrderNumber, true)"
            >
              <div v-if="order.PaymentFailurePrompt">
                {{ $t.pay.rePayDetail }}
              </div>
              <div v-else>{{ $t.pay.onPay }}</div>
            </div>
            <div
              class="btn"
              v-if="order.State === 30"
              @click.stop="actionComfire(order.AllOrderNumber)"
            >
              {{ $t.order.confirm }}
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import Tabs from "@components/common/Tabs.vue";
import Tab from "@components/common/Tab.vue";
import { List, PullRefresh, Image, Sticky } from "vant";
import Vue from "vue";
Vue.use(Image).use(Sticky);

export default {
  name: "Orders",
  components: {
    Tabs,
    Tab,
    "van-list": List,
    "van-pull-refresh": PullRefresh,
  },
  data() {
    return {
      state: this.$route.query.state ? this.$route.query.state : "0",
      page: 0,
      pageSize: 10,
      content: [],
      loading: false,
      finished: false,
      error: false,
      errorText: "",
      refreshing: false,
      hideHeader: false,
      oldUrl: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.oldUrl = from.path;
    });
  },
  beforeDestroy() {
    this.hideHeader = false;
    document.getElementsByClassName("van-nav-bar")[0].style.display = "block";
  },
  mounted() {
    window.document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        document.getElementsByClassName("orders-top-row")[0].style.paddingTop =
          "env(safe-area-inset-top)";
      } else {
        document.getElementsByClassName("orders-top-row")[0].style.paddingTop =
          "0px";
      }
    });
    this.$nextTick(() => {
      //console.log(this.oldUrl);
      if (this.oldUrl.indexOf("payCompletion") || this.oldUrl == "/") {
        this.hideHeader = true;
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "none";
      } else {
        this.hideHeader = false;
        document.getElementsByClassName("van-nav-bar")[0].style.display =
          "block";
      }
    });
  },
  watch: {
    state() {
      this.loading = true;
      this.finished = false;
      this.error = false;
      this.content = [];
      this.request(1);
    },
  },
  created() {
    const { BackUser } = this.$route.query;
    if (BackUser) {
      this.$route.meta.backUser = true;
    } else {
      this.$route.meta.backUser = false;
    }
  },
  methods: {
    onClickLeft() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.backUser);
    },
    stateChanged(state) {
      this.state = state;
    },
    request(page) {
      const param = {
        PageIndex: page,
        PageSize: this.pageSize,
      };
      if (this.state !== "0") {
        param.ScreenValues = this.state;
      }
      this.$api.order
        .loadOrderByPage(param)
        .then((res) => {
          if (this.refreshing) {
            this.content = res.data;
            this.refreshing = false;
          } else {
            this.content = this.content.concat(res.data);
            this.loading = false;
          }
          this.page = res.meta.page;
          this.finished = this.page >= res.meta.lastPage;
        })
        .catch((err) => {
          this.loading = false;
          this.error = true;
          this.errorText = err.message;
          this.refreshing = false;
        });
    },
    actionDetail(id, isShow) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: `orderDetails/${id}`,
        name: "OrderDetails",
        query: [
          {
            name: "ShowPayment",
            value: isShow ? isShow : false,
          },
        ],
      });
    },
    actionDelete(orderNumber) {
      this.$commonMethod.customAlert(
        {
          message: this.$t.alert.orderCancelText,
          cancelButtonText: this.$t.alert.cancelButtonText,
          confirmButtonText: this.$t.alert.confirmButtonText,
        },
        () => {
          this.cancelOrder(orderNumber);
        },
        () => {}
      );
    },
    cancelOrder(orderNumber) {
      this.$commonMethod.showLoading();
      this.$api.order
        .cancelOrder({
          OrderNumber: orderNumber,
        })
        .then((res) => {
          const index = this.content.findIndex((order) => {
            return order.AllOrderNumber === orderNumber;
          });
          this.content.splice(index, 1);
          this.$commonMethod.hideLoading();
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
    actionLogistics(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: `orderLogistics/${id}`,
        name: "OrderLogistics",
        query: [],
      });
    },
    actionComfire(orderNumber) {
      this.$commonMethod.customAlert(
        {
          message: this.$t.alert.orderConfirmText,
          cancelButtonText: this.$t.alert.cancelButtonText,
          confirmButtonText: this.$t.alert.confirmButtonText,
        },
        () => {
          this.requestComfire(orderNumber);
        },
        () => {}
      );
    },
    requestComfire(orderNumber) {
      this.$commonMethod.showLoading();
      this.$api.order
        .ConfirmReceipt({
          OrderNumber: orderNumber,
        })
        .then((res) => {
          this.content = [];
          const index = this.content.findIndex((order) => {
            return order.AllOrderNumber === orderNumber;
          });
          this.content.splice(index, 1);
          this.$commonMethod.hideLoading();
        })
        .catch((err) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~assets/scss/var";
@import "~assets/scss/publicStyle";

.orders {
  header {
    height: auto;
    background: linear-gradient(left, #71d283, #01aaa3);
    ::v-deep .van-nav-bar {
      background: transparent;
      line-height: 2.5;
    }
    ::v-deep .van-nav-bar__title {
      color: #fff;
      font-size: 1.2rem;
    }
    ::v-deep .van-nav-bar__arrow {
      color: #fff;
      font-size: 2rem;
    }
    ::v-deep .van-hairline--bottom::after {
      display: none;
    }
  }
  .van-pull-refresh {
    overflow: unset;
  }
  .top-bar {
    // position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  .orders-top-row {
    width: 100%;
    text-align: center;
    background-color: #ffffff;
    transition: padding-top 0.1s;
  }

  .list {
    padding: 10px 15px;

    .order {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 2.5px 6.5px 1px rgb(0 0 0 / 10%);
      & + .order {
        margin-top: 10px;
      }

      .product {
        .header {
          padding: 15px 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .orderId {
            margin: 0;
            font-size: 14px;
            color: black;
          }
        }
        .sub-header {
          padding: 5px 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .title {
            margin: 0;
            font-size: 12px;
            color: black;
            font-weight: bold;
          }
          .state {
            margin: 0;
            font-size: 12px;
            width: 100%;
            text-align: right;
            color: $-color-light-gray;

            &.blue {
              color: $-color-blue;
            }

            &.red {
              color: $-color-red;
            }
          }
        }
        .item {
          padding: 15px;
          border-bottom: 0.5px solid $-color-background;
          display: flex;
          align-items: flex-start;

          .shopImg {
            .ImgIcon {
              height: 56px;
              width: 56px;
              object-fit: cover;
            }
            ::v-deep .van-image__img {
              height: 56px;
              width: 56px;
              position: relative;
              object-fit: cover;
            }
            ::v-deep .van-image__error {
              height: 56px;
              width: 56px;
              position: relative;
              object-fit: cover;
            }
          }

          .content {
            flex: 1;
            margin-left: 10px;

            .name {
              margin: 0;
              font-size: 12px;
              min-height: 34px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
              white-space: normal;
            }

            .detail {
              display: flex;
              justify-content: space-between;
              align-items: baseline;
              margin-top: 7px;
              font-size: 12px;
              word-break: break-word;
              overflow: hidden;
              .size {
                margin: 0;
                width: 90%;
                color: $-color-light-gray;
              }
              .count {
                margin: 0;
              }
            }
          }
        }
      }
      .dateTime {
        padding: 10px 15px 0px 15px;
        font-size: 14px;
        text-align: right;
        color: #a7a7a7;
      }
      .info {
        padding: 10px 15px;
        text-align: right;
        margin: 0;
        // line-height: 49px;
        border-bottom: 0.5px solid $-color-background;
        font-size: 14px;
        color: $-color-light-gray;

        .m {
          color: $-color-red;
          font-weight: bold;
          margin-left: 10px;
        }
        .money {
          color: $-color-red;
          font-weight: bold;
          font-size: 18px;
          margin-left: 4px;
        }
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 15px;

        .btn {
          width: 75px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          color: white;
          background: #01aaa3;
          border-radius: 16px;
          text-align: center;
          & + .btn {
            margin-left: 8px;
          }
          &.blue {
            background: $-color-blue;
          }

          &.red {
            background: $-color-red;
          }
        }
      }
    }
  }
}
</style>
