<template>
  <div class="tab" @click="onClick">
    <p :class="{active: isActive}">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    name: String,
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/scss/var';
.tab {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 13px;
    color: #333;

    &.active {
      font-weight: bold;
      color: $-color-primary;
    }
  }
}
</style>
